<template>
  <b-card-code>
    <div class="d-flex justify-content-between align-items-center">
      <div class="ml-1">
        <h4 class="text-primary">{{ team_name }}</h4>
      </div>
      <div>
        <b-button
          v-if="
            this.$store.state.app.user.permissions.includes('user.add_user')
          "
          @click="showSidebar()"
          type="button"
          variant="success"
          size="sm"
        >
          Add Users
        </b-button>
        <b-button @click="goBack()" class="m-1" size="sm" variant="primary"
          >Back</b-button
        >
      </div>
    </div>
    <b-table
      v-if="items.length"
      responsive
      id="orgTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      hover
      style="min-height: 200px"
    >
      <template #cell(full_name)="data">
        <span class="text-capitalize">{{ data.item.full_name }}</span>
      </template>
      <template #cell(roles)="data">
        <b-badge
          variant="primary"
          v-for="item in data.item.groups"
          :key="item.id"
        >
          {{ item.name }}
        </b-badge>
      </template>

      <template #cell(status)="data">
        <b-badge variant="success" v-if="data.item.is_active">Yes</b-badge>
        <b-badge variant="danger" v-else>No</b-badge>
      </template>

      <template #cell(staff)="data">
        <b-badge variant="success" v-if="data.item.is_staff">Yes</b-badge>
        <b-badge variant="danger" v-else>No</b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="removeUser(data.item.id)"
            >Remove</b-dropdown-item
          >
          <b-dropdown-item @click="editUser(data.item.id)"
            >Edit</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item @click="deleteUser(data.item.id)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>
    <div v-else>
      <div
        class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
      >
        <feather-icon icon="DatabaseIcon" size="25" class="mb-50" />
        <h3 class="font-weight-bolder">No Data Found</h3>
      </div>
    </div>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    />
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <AttachUser
        :org_id="organization"
        :team_id="team_id"
        :closeSidebar="closeSidebar"
        :sidebar_title="sidebar_title"
      />
    </b-sidebar>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import AttachUser from "./components/attach_users.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSidebar,
    VBToggle,
    AttachUser,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      org_id: this.$store.state.app.org_id,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      team_name: "",
      team_id: parseInt(this.$route.params.id),
      organization: 0,
      sidebar_title: "",
      fields: [
        // { key: 'id', label: '#' },
        { key: "full_name", label: "Full Name" },
        // { key: "first_name", label: "First Name" },
        // { key: "last_name", label: "Last Name" },
        // { key: "profile.organization", label: "Organization" },
        // { key: "roles", label: "Roles" },
        { key: "status", label: "Status" },
        // { key: "staff", label: "Staff" },
        { key: "actions", label: "Actions" },
      ],
      test: [],
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      let url =
        process.env.VUE_APP_BASEURL +
        "user/users/get-all-users/?team_id=" +
        this.$route.params.id;
      if (this.is_staff) {
        url = url + "&q_org_id=" + this.org_id;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.test = res.data;
        res.data.map(function (value, key) {
          // console.log(res.data[key].profile.organization)
          if (res.data[key].profile.organization != null) {
            res.data[key].profile.organization =
              res.data[key].profile.o_profile.org_name;
          }
        });
        this.items = res.data;
        console.log(this.items);
        this.rows = res.data.length;
      });
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "user/teams/" + this.$route.params.id,
      }).then((res) => {
        this.team_name = res.data.team_name;
        this.organization = res.data.o_profile.sso_ref_id;
      });
    },
    addUsers() {
      this.$router.push({ name: "Add User" });
    },
    editUser(id) {
      this.$router.push({ name: "Edit User", params: { id: id } });
    },
    deleteUser(id) {
      if (confirm("Do you really want to delete?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "user/users/" + id + "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
          //if(res.data.org_id){
          // this.$router.push({name: 'Organizations'});
          // }
        });
      }
    },
    removeUser(user_id) {
      if (confirm("Do you really want to remove this user from team?")) {
        let users = [];
        users.push(user_id);
        let data = {
          team_id: this.$route.params.id,
          users: users,
        };
        const a_options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "user/teams/remove-users/",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          if (res.data.status == "success") {
            this.load();
          }
        });
      }
    },
    showSidebar: function (data) {
      // this.$refs['taskSideBar'].show();
      this.sidebar_title = "Add users to the team: " + this.team_name;
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      // this.isTaskHandlerSidebarActive = true
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      this.load();
    },
    goBack() {
      this.$router.push("/team");
    },
  },
};
</script>