  <template>
  <b-card-code :title="sidebar_title">
    <validation-observer ref="TeamUsersAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addUsers">
        <!-- Organization-->
        <b-form-group label="Add Existing Users" label-for="Users">
          <template #label>
            <span>Add Existing Users</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Users"
            rules="required"
          >
            <v-select
              v-model="users"
              multiple
              label="text"
              :options="user_options"
              @search="fetchOptions"
              autocomplete
              state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || isDisable"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else> Add to team</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
    team_id: {
      type: Number,
      required: true,
    },
    sidebar_title: {
      type: String,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  watch: {},
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      users: [],
      user_options: [],
      page_title: "Add Users",
      isloading: false,
      isDisable: false,
    };
  },

  methods: {
    addUsers: function () {
      this.$refs.TeamUsersAddForm.validate().then((success) => {
        this.isloading = true;
        this.isDisable = true;
        let data = {
          team_id: this.team_id,
          users: this.users,
        };
        const a_options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "user/teams/add-users/",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          if (res.data.status == "success") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isloading = false;
            this.closeSidebar();
            this.users = [];
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
          this.isloading = false;
          this.isDisable = false;
        });
      });
    },
    fetchOptions(search, loading) {
      if (search.length > 1) {
        loading(true);
        // Load Users here
        let url =
          process.env.VUE_APP_BASEURL +
          "user/users/get-all-users" +
          "?name=" +
          search;
        if (this.is_staff) {
          url = url + "&q_org_id=" + this.org_id;
        }
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.user_options = [];
          res.data.map(function (value, key) {
            let a = {
              value: res.data[key].id,
              text:
                res.data[key].full_name.charAt(0).toUpperCase() +
                res.data[key].full_name.slice(1),
            };
            // console.log(res.data[key].profile.organization)
            self.user_options.push(a);
            loading(false);
          });
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>